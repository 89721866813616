.footer-container{
	display: flex;
	width: calc(100% - 144px);
	margin-top: auto;
	margin-bottom: 16px;
}

.footer-logos{
	margin: 16px;
	max-width: 360px;
	min-width: 240px;
	width: 40%;
}