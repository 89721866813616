 
  .base-results{
	margin-top: 30px;
	width: 100%;
  }

  .results-screen{
	margin-top: 30px;
	width: 100%;
  }



 
  