body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
	text-align: center;
  }
  
.base-background {
  background-color: #f1f1f1;
  min-height: 100vh;
  color: rgb(43, 43, 43);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.base-box{
  display: block;
  min-height: 100%;
  width: calc(100% - 144px);
  font-size: calc(10px + 2vmin);
}